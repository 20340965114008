import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import { Icon, IconButton, LinearProgress } from '@mui/material';
import http from '../services/http.service';

const form = {
    value: '',
    error: false,
    message: ''
}

export default function AddSheet(props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [showProgress, setShowProgress] = React.useState(false);
    const [sheets, setSheets] = React.useState([{
        value: '',
        error: false,
        message: ''
    }]);


    const [progress, setProgress] = React.useState(1);


    function isURL(str) {
        var urlRegex = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
        var url = new RegExp(urlRegex, 'i');
        return url.test(str);
    }

    const handleChange = (val, index) => {
        console.log(val)
        let _sheets = [...sheets]
        _sheets[index]['value'] = val
        if (isURL(val)) {
            _sheets[index]['error'] = false
            _sheets[index]['message'] = ''
        }
        else {
            _sheets[index]['error'] = true
            _sheets[index]['message'] = 'Invalid link'
        }
        setSheets(_sheets)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSheets([{ ...form }])
        setProgress(0)
        setOpen(false);
    };

    const removeLink = (index) => {
        if (sheets.length > 1) {
            const _sheets = [...sheets]
            _sheets.splice(index, 1)
            console.log(_sheets)
            setSheets(_sheets)
        }
        else alert('Minimum 1 link')
    }

    const addMore = () => {
        if (sheets.length <= 10) {
            const _sheets = [...sheets]
            const index = _sheets.length - 1
            _sheets.push({ ...form })
            setSheets(_sheets)
        }
        else alert('Maximum 10 sheets links')
    }

    const validate = (links) => {
        return new Promise(resolve => {
            for (let index = 0; index < links.length; index++) {
                const link = links[index];
                if (isURL(link.value)) {
                    link.error = false
                    link.message = ''
                }
                else {
                    link.error = true
                    link.message = 'invalid link'
                }
                if (index === links.length - 1) {
                    resolve(links)
                }
            }
        })
    }

    const submit = async () => {
        try {
            const _sheets = await validate([...sheets])
            setSheets(_sheets)
            const isvalid = _sheets.map(_sheet => {
                if (_sheet.error) return false
                return true
            })
            if (isvalid.includes(false)) alert('please fill valid values')
            else {
                setShowProgress(true)
                // CALL API TO ADD SHEETS
                const body = {
                    token: localStorage.getItem('sheet_access_token'),
                    sheets: sheets.map(sheet => { return sheet.value }),
                    googleId: localStorage.getItem('sheet_google_id')
                }
                console.log(body)
                let pro = 1
                let interval = setInterval(() => {
                    pro++
                    setProgress(pro)
                    if (pro === 92) {
                        pro--
                    }
                }, 1000);
                const response = await http.post(`/Users/fetchSheet`, body)
                if (response.status === 200) {
                    setProgress(100)
                    clearInterval(interval)
                    // SUCCESS
                    if (response.data.statusCode === 200) {
                        handleClose()
                        props.done()
                        setShowProgress(false)
                    }
                    else {
                        setShowProgress(false)
                        alert(response.data.message)
                    }
                }
                else {
                    setShowProgress(false)
                    console.log(response)
                    alert('something went wrong')
                }
            }
            // console.log(sheets)
        }
        catch (e) {
            console.log(e)
            setShowProgress(false)
        }
    }
    return (
        <div>
            <Fab onClick={handleClickOpen} style={{ position: 'fixed', bottom: 10, right: 10 }} color="primary" aria-label="add">
                <Icon>add</Icon>
            </Fab>
            <Dialog open={open}>
                {showProgress ?
                    <div>
                        <div className='w-96 py-4 px-5 bg-white shadow-md rounded-md'>
                            <div className='w-full flex justify-between items-center py-1'>
                                <p className='text-sm text-slate-500 font-semibold'>FETCHING DATA...</p>
                                <p className='text-sm text-slate-800 font-bold'>{progress}%</p>
                            </div>
                            <LinearProgress color={progress <= 25 ? 'warning' : progress > 25 && progress < 100 ? 'primary' : 'success'} variant="determinate" value={progress} />
                        </div>
                    </div> :
                    <>
                        <DialogTitle>Provide Google Sheet Links</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Kindly provide valid links of google sheets shared with your account.(Maximum 10 links)
                            </DialogContentText>
                            {sheets.map((sheet, index) => (
                                <TextField
                                    error={sheet.error}
                                    key={index}
                                    autoFocus
                                    // color={sheet.error ? 'error' : 'primary'}
                                    margin="dense"
                                    id={`link${index + 1}`}
                                    label={`Google Sheet Link #${index + 1}`}
                                    type="url"
                                    fullWidth
                                    variant="standard"
                                    helperText={sheet.message}
                                    InputProps={{
                                        endAdornment: <IconButton onClick={() => removeLink(index)}><Icon>cancel</Icon></IconButton>
                                    }}
                                    onChange={(e) => handleChange(e.target.value, index)}
                                />
                            ))}
                            <div className='flex justify-end mt-3'>
                                <Button color='info' onClick={() => addMore()}>
                                    add another link
                                </Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button color='error' onClick={handleClose}>Cancel</Button>
                            <Button variant='contained' color='primary' onClick={submit}>Fetch</Button>
                        </DialogActions>
                    </>
                }
            </Dialog>
        </div>
    );
}

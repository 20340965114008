import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Checkbox, Icon, IconButton, ListItemText } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

const _status = [
    'Interested for Admission',
    'Not Interested for Admission',
    'Call Back',
    'Wrong Number',
    'Switched Off',
    'Interested For Demo',
    'Attending Demo',
    'Need More Demo',
    'Registered',
    'Follow up By Other Team',
    'App downloaded',
    'Call Busy',
    'Call Not Connect',
    'Not Sure for Demo',
    'Number not exist',
    'Number not in service',
    'Not Attending',
    'Not Attended',
    'Attended',
    'Final Negative',
    'Call Not Attending',
    'Negative',
    'Aptain'
]

const _source = [
    'Bulk',
    'WFH',
    'Form Response - Academic',
    'Seminar',
    'Commerce Olympiad',
    'AGST',
    'Walk-In',
    'Refferal',
    'Telephonic',
    'Social Media',
    'RDM',
    'Exam Center',
    'Just Dial',
    'Tie-Up',
    'Coaching Seminar'
]

const _currentClass = [
    '9th',
    '10th',
    '11th',
    '12th',
    'CAF',
    'CA-Inter',
    '11th Commerce',
    '12th Commerce',
    'CSEET',
    '8th',
    'CS Executive'
]

const _session = [
    '2020-2021',
    '2021-2022',
    '2022-2023',
    '2023-2024',
    '2024-2025',
    'Other'
]

const _stream = [
    'Commerce',
    'PCM',
    'PCB',
    'Arts',
    'Other'
]

const _boards = [
    'CBSE',
    'ICSE',
    'MP Board',
    'State Board'
]

const _medium = [
    'Hindi',
    'English',
    'Both(Hindi/English)'
]

const _appDwnStatus = [
    'App Downloaded',
    'Wallet Balance Given',
    'Lecture Started',
    'First Test Given'
]


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

export default function Filter(props) {
    const [status, setStatus] = React.useState([]);
    const [session, setSession] = React.useState([]);
    const [board, setBoard] = React.useState([]);
    const [currentClass, setCurrentClass] = React.useState([]);
    const [source, setSource] = React.useState([]);
    const [stream, setStream] = React.useState([]);
    const [appDwnStatus, setAppDwnStatus] = React.useState([]);
    const [medium, setMedium] = React.useState([]);

    const statusChange = (event) => {
        props.status(event.target.value)
        setStatus(event.target.value);
    };

    const sessionChange = (event) => {
        props.session(event.target.value)
        setSession(event.target.value);
    }

    const boardChange = (event) => {
        props.board(event.target.value)
        setBoard(event.target.value);
    }

    const currentClassChange = (event) => {
        props.currentClass(event.target.value)
        setCurrentClass(event.target.value);
    }

    const sourceChange = (event) => {
        props.source(event.target.value)
        setSource(event.target.value);
    }

    const streamChange = (event) => {
        props.stream(event.target.value)
        setStream(event.target.value);
    }

    const appDwnStatusChange = (event) => {
        props.appDwnStatus(event.target.value)
        setAppDwnStatus(event.target.value);
    }

    const mediumChange = (event) => {
        props.medium(event.target.value)
        setMedium(event.target.value);
    }


    const clearFilter = () => {
        setStatus([])
        setSession([])
        setSource([])
        setStream([])
        setBoard([])
        setMedium([])
        setAppDwnStatus([])
        setCurrentClass([])
        props.clear()
    }

    return (
        <div className='py-4 px-4 relative'>
            <h3 className='text-sm text-gray-800 mb-2 ml-2'>Choose Filters</h3>
            <IconButton color='error' onClick={() => clearFilter()} className='absolute top-0 right-0'>
                <Tooltip title="clear filters">
                    <Icon>cancel</Icon>
                </Tooltip>
            </IconButton>
            <div className='flex flex-wrap justify-start items-center'>
                <FormControl className='flex-1' sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status}
                        label="Status"
                        onChange={statusChange}
                        renderValue={(selected) => selected.join(', ')}
                        multiple
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""}>
                            <Checkbox
                                checked={
                                    status.includes("")
                                }
                            />
                            <ListItemText primary={"None"} />
                        </MenuItem>
                        {_status?.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                <Checkbox
                                    checked={
                                        status.includes(val)
                                    }
                                />
                                <ListItemText primary={val} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className='flex-1' sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Session</InputLabel>
                    <Select
                        value={session}
                        label="Session"
                        onChange={sessionChange}
                        renderValue={(selected) => selected.join(', ')}
                        multiple
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""}>
                            <Checkbox
                                checked={
                                    session.includes("")
                                }
                            />
                            <ListItemText primary={"None"} />
                        </MenuItem>
                        {_session?.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                <Checkbox
                                    checked={
                                        session.includes(val)
                                    }
                                />
                                <ListItemText primary={val} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className='flex-1' sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Board</InputLabel>
                    <Select
                        value={board}
                        label="Board"
                        onChange={boardChange}
                        renderValue={(selected) => selected.join(', ')}
                        multiple
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""}>
                            <Checkbox
                                checked={
                                    board.includes("")
                                }
                            />
                            <ListItemText primary={"None"} />
                        </MenuItem>
                        {_boards?.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                <Checkbox
                                    checked={
                                        board.includes(val)
                                    }
                                />
                                <ListItemText primary={val} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className='flex-1' sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Current Class</InputLabel>
                    <Select
                        value={currentClass}
                        label="Current Class"
                        onChange={currentClassChange}
                        renderValue={(selected) => selected.join(', ')}
                        multiple
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""}>
                            <Checkbox
                                checked={
                                    currentClass.includes("")
                                }
                            />
                            <ListItemText primary={"None"} />
                        </MenuItem>
                        {_currentClass?.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                <Checkbox
                                    checked={
                                        currentClass.includes(val)
                                    }
                                />
                                <ListItemText primary={val} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className='flex-1' sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Source</InputLabel>
                    <Select
                        value={source}
                        label="Source"
                        onChange={sourceChange}
                        renderValue={(selected) => selected.join(', ')}
                        multiple
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""}>
                            <Checkbox
                                checked={
                                    source.includes("")
                                }
                            />
                            <ListItemText primary={"None"} />
                        </MenuItem>
                        {_source?.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                <Checkbox
                                    checked={
                                        source.includes(val)
                                    }
                                />
                                <ListItemText primary={val} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className='flex-1' sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Stream</InputLabel>
                    <Select
                        value={stream}
                        label="Stream"
                        onChange={streamChange}
                        renderValue={(selected) => selected.join(', ')}
                        multiple
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""}>
                            <Checkbox
                                checked={
                                    stream.includes("")
                                }
                            />
                            <ListItemText primary={"None"} />
                        </MenuItem>
                        {_stream?.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                <Checkbox
                                    checked={
                                        stream.includes(val)
                                    }
                                />
                                <ListItemText primary={val} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className='flex-1' sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>App Download Status</InputLabel>
                    <Select
                        value={appDwnStatus}
                        label="App Download Status"
                        onChange={appDwnStatusChange}
                        renderValue={(selected) => selected.join(', ')}
                        multiple
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""}>
                            <Checkbox
                                checked={
                                    appDwnStatus.includes("")
                                }
                            />
                            <ListItemText primary={"None"} />
                        </MenuItem>
                        {_appDwnStatus?.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                <Checkbox
                                    checked={
                                        appDwnStatus.includes(val)
                                    }
                                />
                                <ListItemText primary={val} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl className='flex-1' sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Medium</InputLabel>
                    <Select
                        value={medium}
                        label="Medium"
                        onChange={mediumChange}
                        renderValue={(selected) => selected.join(', ')}
                        multiple
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""}>
                            <Checkbox
                                checked={
                                    medium.includes("")
                                }
                            />
                            <ListItemText primary={"None"} />
                        </MenuItem>
                        {_medium?.map((val, index) => (
                            <MenuItem key={index} value={val}>
                                <Checkbox
                                    checked={
                                        medium.includes(val)
                                    }
                                />
                                <ListItemText primary={val} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}
